import { useCallback, useRef, useState } from 'react';

export function useDebounce(callback, delay) {
  const timer = useRef();

  const debouncedCallback = useCallback(
    (...args) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  return debouncedCallback;
}

export function useDebounceForMultipleFields(callback, delay) {
  const [timer, setTimer] = useState({});

  const debouncedCallback = useCallback(
    (...args) => {
      if (args[1] in timer) {
        clearTimeout(timer[args[1]]);
      }

      setTimer({
        ...timer,
        [args[1]]: setTimeout(() => {
          callback(...args);
        }, delay),
      });
    },
    [callback, delay],
  );

  return debouncedCallback;
}
