import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as resources from './languages';

i18next.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  defaultNS: 'translation',
  fallbackNS: 'translation',
  resources,
  react: {
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'b', 'code'],
  },
});

export default i18next;
